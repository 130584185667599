import { inCase } from '~plumbing/utils'
import {
  actions as customerActions,
  actionsTypes,
} from './CustomerManagementActions'

const defaultState = {
  customers: {
    CustomerData: [],
    mnpPlus: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const customerReducer = inCase.curry('customerReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_CUSTOMERS]: fetchAllCustomers,
    [actionsTypes.FETCH_ALL_CUSTOMERS_SUCCESS]: fetchAllCustomersSuccess,
    [actionsTypes.FETCH_ALL_CUSTOMERS_FAILED]: fetchAllCustomersFailed,
    [actionsTypes.UPDATE_CUSTOMERS_MNP]: setCustomersMnp,
    [actionsTypes.UPDATE_CUSTOMERS_MNP_SUCCESS]: setCustomersMnpSuccess,
    [actionsTypes.UPDATE_CUSTOMERS_MNP_FAILED]: setCustomersMnpFailed,
  })

function fetchAllCustomers(state, action) {
  return {
    ...state,
  }
}
function fetchAllCustomersSuccess(state, action) {
  return {
    ...state,
    customers: {
      CustomerData:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchAllCustomersFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function setCustomersMnp(state, action) {
  return {
    ...state,
  }
}
function setCustomersMnpSuccess(state, action) {
  return {
    ...state,
    customers: {
      mnpPlus:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function setCustomersMnpFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default customerReducer
