/* eslint-disable no-unused-vars */
import { inCase } from '~plumbing/utils'
import {
  actions as coverageActions,
  actionsTypes,
} from './CoverageAction'

const defaultState = {
  Coverage: {
    CoverageData: [],
    CoverageDataById: [],
    PrefixsWithSamples: {},
    Requests: '',
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const sampleReducer = inCase.curry('sampleReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_COVERAGES]: fetchCoverages,
    [actionsTypes.FETCH_ALL_COVERAGES_SUCCESS]: fetchCoveragesSuccess,
    [actionsTypes.FETCH_ALL_COVERAGES_FAILED]: fetchCoveragesFailed,
    [actionsTypes.FETCH_ALL_PREFIXES_SAMPLES]: fetchPrefixSamples,
    [actionsTypes.FETCH_ALL_PREFIXES_SAMPLES_SUCCESS]: fetchPrefixSamplesSuccess,
    [actionsTypes.FETCH_ALL_PREFIXES_SAMPLES_FAILED]: fetchPrefixSamplesFailed,
    [actionsTypes.COVERAGES_BY_ID]: coveragesById,
    [actionsTypes.COVERAGES_BY_ID_SUCCESS]: coveragesByIdSuccess,
    [actionsTypes.COVERAGES_BY_ID_FAILED]: coveragesByIdFailed,
    [actionsTypes.DELETE_COVERAGES]: deleteCoverages,
    [actionsTypes.DELETE_COVERAGES_SUCCESS]: deleteCoveragesSuccess,
    [actionsTypes.DELETE_COVERAGES_FAILED]: deleteCoveragesFailed,
    [actionsTypes.CREATE_SAMPLE]: createSamples,
    [actionsTypes.CREATE_SAMPLE_SUCCESS]: createSamplesSuccess,
    [actionsTypes.CREATE_SAMPLE_FAILED]: createSamplesFailed,
  })

function fetchCoverages(state, action) {
  return {
    ...state,
  }
}
function fetchCoveragesSuccess(state, action) {
  return {
    ...state,
    Coverage: {
      CoverageData:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchCoveragesFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchPrefixSamples(state, action) {
  return {
    ...state,
  }
}
function fetchPrefixSamplesSuccess(state, action) {
  return {
    ...state,
    Coverage: {
      PrefixsWithSamples:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchPrefixSamplesFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function coveragesById(state, action) {
  return {
    ...state,
  }
}
function coveragesByIdSuccess(state, action) {
  return {
    ...state,
    Coverage: {
      CoverageDataById:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function coveragesByIdFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function createSamples(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function createSamplesSuccess(state, action) {
  return {
    ...state,
    Coverage: {
      Requests:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function createSamplesFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function deleteCoverages(state, action) {
  return {
    ...state,
  }
}
function deleteCoveragesSuccess(state, action) {
  return {
    ...state,
  }
}
function deleteCoveragesFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default sampleReducer
