import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  FETCH_ALL_CUSTOMERS: 'discovery/CustomerMangement/Customers',
  FETCH_ALL_CUSTOMERS_SUCCESS: 'discovery/CustomerMangement/CustomersSucess',
  FETCH_ALL_CUSTOMERS_FAILED: 'discovery/CustomerMangement/CustomersFail',
  UPDATE_CUSTOMERS_MNP: 'discovery/CustomerMangement/Mnp',
  UPDATE_CUSTOMERS_MNP_SUCCESS: 'discovery/CustomerMangement/MNPSucess',
  UPDATE_CUSTOMERS_MNP_FAILED: 'discovery/CustomerMangement/MNPFail',
}
export const actions = Object.keys(actionsTypes)
  .reduce((accum, id) => {
    const creatorName = _camelCase(id)

    const creatorFunction = function _(payload, meta) {
      return {
        type: _get(actionsTypes, id),
        payload,
        meta,
      }
    }

    // eslint-disable-next-line no-param-reassign
    accum[creatorName] = renameFunc(creatorFunction, creatorName)
    return accum
  }, {})
