/* global FS */
import Rx from 'rxjs' // eslint-disable-line

import {
  observableFromPromiseProperties,
} from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as customerActions } from './CustomerManagementActions'
import apis from '../apis'

const customerApis = observableFromPromiseProperties(apis, Rx)

export default [
  getCustomerData,
  setMnpData,
]

export function getCustomerData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_ALL_CUSTOMERS)
    .switchMap(({ payload }) => {
      return customerApis
        .customerData(payload)
        .map((res) => {
          return customerActions.fetchAllCustomersSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_CUSTOMERS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function setMnpData(action$, { getState }) {
  return action$.ofType(actionsTypes.UPDATE_CUSTOMERS_MNP)
    .switchMap(({ payload }) => {
      return customerApis
        .updateMnp(payload)
        .map((res) => {
          return customerActions.updateCustomersMnpSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_CUSTOMERS_MNP_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
